<template>
    <div class="rules-wrap">
        <div class="rules-title">活动流程：</div>
        <ul>
            <li>1、复制邀请链接，发送给好友</li>
            <li>2、好友打开链接，填写相关信息，并提交申请体验</li>
            <li>3、好友沃创云平台账户充值并消费（购买平台任一套餐服务）</li>
            <li>4、您随即可享受该好友消费金额的10%佣金分成</li>
        </ul>
        <div class="rules-title">活动规则：</div>
        <ul>
            <li>1、邀请的好友完成充值后，系统随即绑定其为您的分佣好友，该好友未来3个月内每笔平台消费，您均可获得10%佣金分成</li>
            <li>2、“待返佣金”指好友实际充值总金额的10%分佣；“可提现佣金”指好友在沃创云平台实际消费金额的10%分佣</li>
            <li>3、分佣好友人数没有上限，您邀请的好友越多，享受的分佣则越多</li>
            <li>4、为防止恶意刷单，“可提现佣金”实际到账时间及金额，以分佣好友所购买的外呼系统、优选商品等产品消费完毕或到期为准；如好友发生退款行为，则相应佣金根据实际消费或到期金额折算更新</li>
        </ul>
        <ul>
            <li>活动最终解释权归沃创云所有</li>
            <li>若有疑问，请联系客服：</li>
            <li>WX： 13711109171</li>
        </ul>
        <shareLink/>
    </div>
</template>

<script>
    import shareLink from '@/components/mobile/shareLink'
    import { getStorage } from '@/utils/common'
    export default {
        components: {
            shareLink
        },
        created(){
            getStorage('userId') && this.$store.dispatch('getUserInfo')
        }
    }
</script>

<style lang="scss" scoped>
.rules-wrap{
    padding: r(40) r(50) r(40) r(40);
    .rules-title{
        @include font(r(32));
        margin-bottom: r(22);
    }
    ul{
        li{
            @include font(r(28),#697085);
            margin-bottom: r(10);
            line-height: r(48);
            &:last-child{
                margin-bottom: r(40);
            }
        }
    }
    ::v-deep .share-warp{
        margin-top: r(70);
    }
}
</style>